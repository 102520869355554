<template>
  <v-main class="text-center">
    <v-row>
      <v-col>
        <h1 class="font-weight-bold mb-3 text-uppercase">
          алтайская краевая федерация<br />джиу-джитсу
        </h1>

        <img
          v-if="startAnimation"
          class="animate"
          src="/img/logo.svg"
          height="200px"
        />
      </v-col>
    </v-row>
    <v-subheader>Наши направления</v-subheader>
    <v-row>
      <v-col class="text-left">
        <v-card class="ma-2">
          <v-card-title> Файтинг </v-card-title>
          <v-card-subtitle>Бои</v-card-subtitle>
          <v-card-text>
            Бои по правилам Джиу-Джитсу проводятся на татами. Во время поединка
            спортсмены применяют ударную, бросковую технику, технику борьбы в
            партере, болевые и удушающие приемы. Экипировка спортсменов —
            ударно-захватные перчатки с открытыми пальцами, накладки на ноги и
            борцовское кимоно из плотной ткани. Бой судят 3 рефери, по оценкам
            которых определяется победитель.
          </v-card-text>
        </v-card>
        <v-card class="ma-2">
          <v-card-title> Не ваза </v-card-title>
          <v-card-subtitle>Бразильское джиу-джитсу</v-card-subtitle>
          <v-card-text>
            Иисключительно умный вид борьбы, где решающее значение имеет не
            грубая сила и функциональная форма, а прежде всего технический
            арсенал спортсмена. Борьба развивает чувство баланса, распределение
            собственного веса и веса партнера во время схватки за счёт рычагов и
            перемещений, мышление спортсмена должно быть открыто для
            своевременного реагирования на действия соперника. Уникальность
            этого вида борьбы заключается в том, что каждый может себе позволить
            заниматься данным видом спорта, не зависимо от возраста и физических
            кондиций. Методология тренировочного процесса позволяет изучить
            множество различных подводящих упражнений, развивающих гибкость и
            пластику передвижения. Для познания различных видов прохождения
            контролей (guard), удержаний и техник, направленных на завершение
            схватки путем болевого воздействия или удушающего приёма, много
            времени уделяется работе в паре с партнёром. Не ваза (бразильское
            джиу-джитсу) — это вид борьбы, на 80% состоящий из технических
            действий в «партере». Много времени уделяется позиционной борьбе,
            т.к. выгодная позиция на или под противником — это шаг к победе.
          </v-card-text>
        </v-card>
        <v-card class="ma-2">
          <v-card-title> Дуо-систем </v-card-title>
          <v-card-subtitle>Парные техники</v-card-subtitle>
          <v-card-text>
            Парные технические соревнования проводятся между 2мя парами. Каждая
            пара поочередно показывает свое решение т.е. технику против заданной
            судьей атаки. Атаки это – захваты, обхваты, удары, атаки с оружием.
            Заранее пара не знает какую именно атаку укажет рефери. После
            проведения техник спортсменам выставляются оценки, по сумме которых
            определяется пара – победитель. Подготовка к подобным соревнованиям
            позволяет наработать свой собственный безотказный арсенал
            контрприемов которые могут безошибочно спасти жизнь при нападении.
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-main>
</template>

<script>
export default {
  data: () => ({
    startAnimation: false,
  }),
  name: "Home",
  mounted() {
    this.startAnimation = true;
    this.$nextTick(() => {
      document.dispatchEvent(new Event("x-app-rendered"));
    });
  },
};
</script>
<style scoped>
@keyframes bounceIn {
  0% {
    transform: scale(0.5);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

.animate {
  animation: bounceIn 1s;
}
</style>
